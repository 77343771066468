/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Testimonials from './Testimonials';
import { Section, IntroTextContainer, Button, VideoButton, PosterImage, PromotionalContainer,
  InformationContainer, PlatformInformation, PlatformItemContainer, PlatformItem, LogoContainer,
  HealthContainer, GetStartedContainer } from './styles';

import { isValidEmail } from '../../utils/methods';

import { saveContactUsInfo } from '../../redux/actions';

const DemoPopup = React.lazy(() => import('../WhyAmigoCommon/DemoPopup'));
import {COMPANY_NAME} from '../../utils/config';
import { Suspense } from 'react';
import Waiting from '../Waiting';
class MainDashboard extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      showModal: false
    }
  }

  onSubmit = () => {
    const { saveContactUsInfo, history } = this.props;
    const { email } = this.state;
    if(email.trim() !== '' && isValidEmail(email)) {
      saveContactUsInfo('email', email);
      history.push('/contact');
    }
  };

  changeModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    const { history } = this.props;
    const { showModal } = this.state;
    return (
      <React.Fragment>
        <Section firstChild margin="0">
          <IntroTextContainer>
            <h1>
              {`It's Work Life Balance`}
            </h1>
            <p>
              We give your team the power to easily
              <br />manage wellbeing, engagement,
              <br />recognition, and rewards all-in-one platform.
            </p>
            <div className="wrap">
              <Button onClick={() => history.push('/contact')} noMargin={1}>BOOK A DEMO</Button>
              <VideoButton onClick={() => this.changeModal()}>
                <i className="fa fa-play" />
                QUICK INTRO VIDEO
              </VideoButton>
            </div>
          </IntroTextContainer>
          <PosterImage>
            {COMPANY_NAME == 'Woliba' ?
              <picture>
                <source srcSet="/public/images/HomePageV2/graphic-iphone-woliba.png" type="image/png"/>
                <img src="/public/images/HomePageV2/graphic-iphone-woliba.png" title="Poster Image"/>
              </picture> : <picture>
                <source srcSet="/public/images/HomePageV2/graphic-iphone.png" type="image/png"/>
                <img src="/public/images/HomePageV2/graphic-iphone.png" title="Poster Image"/>
              </picture>}
          </PosterImage>
        </Section>
        <PromotionalContainer>
          <div>
            <span>SERVING COMPANIES GLOBALLY</span>
            <LogoContainer width="80px" height="80px">
              <picture>
                <source srcSet="/public/images/HomePageV2/traverse-logo.png"/>
                <img src="/public/images/HomePageV2/traverse-logo.png"/>
              </picture>
            </LogoContainer>
            <LogoContainer width="120px">
              <picture>
                <source srcSet="/public/images/HomePageV2/donan-logo.png"/>
                <img src="/public/images/HomePageV2/donan-logo.png"/>
              </picture>
            </LogoContainer>
            <LogoContainer width="140px">
              <picture>
                <source srcSet="/public/images/HomePageV2/parkview-logo.png"/>
                <img src="/public/images/HomePageV2/parkview-logo.png"/>
              </picture>
            </LogoContainer>
            <LogoContainer width="90px">
              <picture>
                <source srcSet="/public/images/HomePageV2/pure-logo.png"/>
                <img src="/public/images/HomePageV2/pure-logo.png"/>
              </picture>
            </LogoContainer>
            <LogoContainer width="90px">
              <picture>
                <source srcSet="/public/images/HomePageV2/laerdal-logo.png"/>
                <img src="/public/images/HomePageV2/laerdal-logo.png"/>
              </picture>
            </LogoContainer>
          </div>
        </PromotionalContainer>
        <InformationContainer>
          <h3>Workplace Wellbeing at Your Fingertips</h3>
          <picture className="tailorBlock">
            <source srcSet="/public/images/HomePageV2/illustration-cart.png"/>
            <img src="/public/images/HomePageV2/illustration-cart.png"/>
          </picture>
          <p>We tailor the program towards your organizational culture and goals so you can manage employee wellbeing,
            engagement, and recognition with just a few clicks.</p>
          <PlatformInformation>
            <h4>Better organize and communicate your initiatives on one platform</h4>
            <PlatformItemContainer>
              <PlatformItem>
                <div>
                  <picture>
                    <source srcSet="/public/images/HomePageV2/wellness-icon.png"/>
                    <img src="/public/images/HomePageV2/wellness-icon.png"/>
                  </picture>
                  <h4>
                    Wellness
                  </h4>
                </div>
                <p>{"Holistic approach to wellbeing that's personalized to every employee"}</p>
              </PlatformItem>
              <PlatformItem>
                <div>
                  <picture>
                    <source srcSet="/public/images/HomePageV2/engagement-icon.png"/>
                    <img src="/public/images/HomePageV2/engagement-icon.png"/>
                  </picture>
                  <h4>
                  Engagement
                  </h4>
                </div>
                <p>Risk assessments and polls with powerful data and analytics</p>
              </PlatformItem>
              <PlatformItem>
                <div>
                  <picture>
                    <source srcSet="/public/images/HomePageV2/recognition-icon.png"/>
                    <img src="/public/images/HomePageV2/recognition-icon.png"/>
                  </picture>
                  <h4>
                  Recognition
                  </h4>
                </div>
                <p>Gamified social platform with peer to peer appreciation and rewards</p>
              </PlatformItem>
              <PlatformItem iconWidth="55px">
                <div>
                  <picture>
                    <source srcSet="/public/images/HomePageV2/intranet-icon.png"/>
                    <img src="/public/images/HomePageV2/intranet-icon.png"/>
                  </picture>
                  <h4>
                  Intranet
                  </h4>
                </div>
                <p>Streamline communication, connect your team and easily manage events</p>
              </PlatformItem>
            </PlatformItemContainer>
          </PlatformInformation>
        </InformationContainer>
        <HealthContainer opacity={1}>
          <div className="tailoredTextContainer">
            <h1>
              <span>Tailored To Your Company</span> Personalized For Each Team Member
            </h1>
            <p>We understand everyone engages differently with wellness
              so we created a holistic approach for everyone to participate. </p>
          </div>
          <div className="roundItemContainer">
            <div className="roundContainer first">
              <picture>
                <source srcSet="/public/images/HomePageV2/lifestyle.png" type="image/png"/>
                <img
                  src="/public/images/HomePageV2/lifestyle.png"
                />
              </picture>
              <p>LIFESTYLE</p>
            </div>
            <div className="roundContainer second">
              <picture>
                <source srcSet="/public/images/HomePageV2/workplace.png" type="image/png"/>
                <img
                  src="/public/images/HomePageV2/workplace.png"
                />
              </picture>
              <p>WORKPLACE</p>
            </div>
            <div className="roundContainer third">
              <picture>
                <source srcSet="/public/images/HomePageV2/health.png" type="image/png"/>
                <img
                  src="/public/images/HomePageV2/health.png"
                />
              </picture>
              <p>HEALTH</p>
            </div>
            <div className="roundContainer fourth">
              <picture>
                <source srcSet="/public/images/HomePageV2/stress.png" type="image/png"/>
                <img
                  src="/public/images/HomePageV2/stress.png"
                />
              </picture>
              <p>STRESS</p>
            </div>
            <div className="roundContainer fifth">
              <picture>
                <source srcSet="/public/images/HomePageV2/fitness.png" type="image/png"/>
                <img
                  src="/public/images/HomePageV2/fitness.png"
                />
              </picture>
              <p>FITNESS</p>
            </div>
            <div className="roundContainer six">
              <picture>
                <source srcSet="/public/images/HomePageV2/apple.png" type="image/png"/>
                <img
                  src="/public/images/HomePageV2/apple.png"
                />
              </picture>
              <p>NUTRITION</p>
            </div>
            <picture>
              <source srcSet="/public/images/HomePageV2/tailored-woliba-logo.png" type="image/png"/>
              <img src="/public/images/HomePageV2/tailored-woliba-logo.png" />
            </picture>
          </div>
        </HealthContainer>
        <InformationContainer paddingBottom="0px">
          <h3>Start a Grassroots Culture Shift</h3>
          <p className="grassRootText">{"Woliba's connected company social network makes it easy for employees to increase their wellbeing while being recognized for doing so. " +
          "This positive shift forward on an individual level starts a culture shift from the ground up."}</p>
          <picture className="grassRootImageContainer">
            <source srcSet="/public/images/HomePageV2/wolibaProfiles.png"/>
            <img src="/public/images/HomePageV2/wolibaProfiles.png"/>
          </picture>
          <Testimonials
            title="ENHANCING EMPLOYEE EXPERIENCE"
            marginTop="60px"
          />
        </InformationContainer>
        <GetStartedContainer>
          <div>
            <h3>
              Are you ready to create a culture of wellbeing?
            </h3>
            <Button onClick={() => history.push('/contact')}>Request a Demo</Button>
          </div>
        </GetStartedContainer>
        <Suspense fallback={<Waiting/>}>
          <DemoPopup showModal={showModal} onClose={this.changeModal} videoUrl="https://vimeo.com/315275491"/>
        </Suspense>
      </React.Fragment>
    );
  }
}

MainDashboard.propTypes = {
  history: PropTypes.object.isRequired,
  saveContactUsInfo: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  saveContactUsInfo: (name, value) => dispatch(saveContactUsInfo(name, value))
});

export default connect(null, mapDispatchToProps)(MainDashboard);
