import React, {Component} from 'react';
import {GrassRootItem, PlatformInformation, PlatformItemContainer} from "./styles";
import PropTypes from "prop-types";
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';


export default class Testimonials extends Component {
  render(){
    const {title, marginTop} = this.props;
    return(
      <PlatformInformation marginTop={marginTop}>
        <h4 style={{fontFamily: 'Rubik-Medium'}}>{title}</h4>
        <PlatformItemContainer>
          <GrassRootItem justifyContent="flex-end">
            <div>
              <picture>
                <source srcSet={ImageUrl + "/images/HomePageV2/testimonial-1.jpg"}/>
                <LazyImage src={ImageUrl + "/images/HomePageV2/testimonial-1.jpg"}/>
              </picture>
              <p>I like the idea behind Woliba. I feel the customer
                service team is helpful and the whole vibe is upbeat.</p>
              <p><span>Ashley Little</span> DONAN</p>
            </div>
          </GrassRootItem>
          <GrassRootItem>
            <div>
              <picture>
                <source srcSet="/images/HomePageV2/testimonial-2.jpeg"/>
                <LazyImage src={ImageUrl + "/images/HomePageV2/testimonial-2.jpeg"}/>
                {/* <img src="/images/HomePageV2/testimonial-2.jpeg"/> */}
              </picture>
              <p>{"It's a great resource to connect our employees with our wellness initiatives and each other."}</p>
              <p><span>Matthew McClellan</span> GTRS</p>
            </div>
          </GrassRootItem>
          <GrassRootItem justifyContent="flex-end">
            <div>
              <picture>
                <source srcSet="/images/HomePageV2/testimonial-3.jpg"/>
                <LazyImage src={ImageUrl + "/images/HomePageV2/testimonial-3.jpg"}/>
                {/* <img src="/images/HomePageV2/testimonial-3.jpg"/> */}
              </picture>
              <p>{"Connects you with other people from your company and encourages you " +
              "to be more active by creating challenges and giving useful articles to read."}</p>
              <p><span>Molly Brady</span> COVENTYA</p>
            </div>
          </GrassRootItem>
          <GrassRootItem>
            <div>
              <picture>
                <source srcSet="/images/HomePageV2/testimonial-4.jpeg"/>
                <LazyImage src={ImageUrl + "/images/HomePageV2/testimonial-4.jpeg"}/>
                {/* <img src="/images/HomePageV2/testimonial-4.jpeg"/> */}
              </picture>
              <p>Motivates me & my coworkers to reach our health & fitness goals</p>
              <p><span>Danella Vasquez</span> NYC SURGICAL</p>
            </div>
          </GrassRootItem>
        </PlatformItemContainer>
      </PlatformInformation>
    )
  }
}

Testimonials.propTypes = {
  title: PropTypes.string.isRequired,
  marginTop: PropTypes.string
};